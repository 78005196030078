.gallery-view-container {
  margin: 0 auto;
  width: 90%;
  max-width: 800px;
}

.month-container {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.month-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
